export const isIE11 = typeof window !== 'undefined' ? !!window.MSInputMethodContext && !!document.documentMode : false;

export const windowWidth = () => {
  if (!isIE11) {
    return;
  }

  if (typeof window === 'undefined') {
    return 1024;
  }

  return window.innerWidth;
};
