import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { stripUnit, math } from 'polished';

import { isIE11, windowWidth } from 'Utils/browser';
import * as v from 'Utils/variables';

const breakpoints = {
  all: '0px',
  xs: v.min420,
  md: v.min720,
  mdWide: v.max1025,
  lg: v.min1025,
  xl: v.min1440,
};

const properties = (o) => `
  ${o.width ? `width: ${convertify(o.width)};` : ''}
  ${o.offsetLeft ? `margin-left: ${convertify(o.offsetLeft)};` : ''}
  ${o.offsetRight ? `margin-right: ${convertify(o.offsetRight)};` : ''}
`;

const makeCss = (...args) => css`
  ${css(...args)}
`;

const makeMediaCss = (media) => (...args) => css`
  @media ${media} {
    ${css(...args)}
  }
`;

const createDefaultMq = (props) =>
  Object.keys(breakpoints).reduce((medias, breakpoint) => {
    const hasBreakpoint = Object.keys(props).find((v) => v === breakpoint);

    if (hasBreakpoint) {
      const obj = props[hasBreakpoint];
      const width = breakpoints[breakpoint];

      if (breakpoint === 'all') {
        const mq = makeCss(properties(obj));

        medias.push(mq);
      } else {
        const mq = makeMediaCss(`(min-width: ${width})`)(properties(obj));

        medias.push(mq);
      }
    }

    return medias;
  }, []);

const createCustomsMq = ({ custom }) => {
  if (!custom) {
    return;
  }

  return Object.keys(custom).reduce((medias, breakpoint) => {
    const obj = custom[breakpoint];
    const { bp } = obj;

    if (bp > 0) {
      const mq = makeMediaCss(`(min-width: ${bp}px)`)(properties(obj));

      medias.push(mq);
    }

    return medias;
  }, []);
};

export const generateMq = (props) => {
  const defaults = createDefaultMq(props);
  const customs = createCustomsMq(props);

  if (customs) {
    return defaults.concat(customs);
  }

  return defaults;
};

export const convertify = (value) => {
  const isNum = (v) => /^\d+$/.test(v);
  const isPercent = (v) => v.toString().includes('%');

  if (isNum(value)) {
    return `${(value / v.columnCount) * 100}%`;
  } else if (isPercent(value)) {
    return value;
  }

  const [val, base] = value.split('/');

  if (!isNum(val) || !isNum(base)) {
    return '100%';
  }

  return `${(val / base) * 100}%`;
};

export const verticallyResponsive = (property, number) => css`
  ${property}: ${number};

  @supports (--css: variables) {
    ${property}: calc(${number} * var(--scale-element));
  }
`;

export const responsiveFont = ({ sizes = `${v.fontSizeMin}, ${v.fontSize}`, limit = v.pageLimit }) => {
  if (!sizes.includes(',')) {
    console.error('Responsive font: You forgot the comma to the `sizes` props');
    return '';
  }

  const [min, max] = sizes.replace(' ', '').split(',');

  if (!min) {
    console.error('Responsive font: You forgot to define the min value to the `sizes` props');
    return '';
  }

  if (!max) {
    console.error('Responsive font: You forgot to define the max value to the `sizes` props');
    return '';
  }

  const unitlessMin = stripUnit(min);
  const unitlessSize = stripUnit(max);
  const fontMultiplier = math(`(${max} - ${min}) / (${limit} - ${v.minMobile})`);
  const fontBaseline = math(`${min} - (${fontMultiplier} * ${v.minMobile})`);

  if (unitlessMin > unitlessSize) {
    console.warn('Responsive font: min equal or greater than size');
    return '';
  }

  const unitlessMinPx = `${unitlessMin}px`;
  const unitlessSizePx = `${unitlessSize}px`;

  return css`
    font-size: ${unitlessMinPx};

    font-size: ${isIE11 ? `calc(${unitlessMinPx} * 1)` : `calc(${unitlessMinPx} * var(--scale-font))`};

    @media (min-width: ${v.minMobile}) {
      font-size: ${unitlessMinPx};

      font-size: ${isIE11 ? `${math(`(${stripUnit(fontMultiplier)} * ${windowWidth()} + (${stripUnit(fontBaseline)}))`)}px` : `calc((${stripUnit(fontMultiplier)} * 100vw + (${fontBaseline})))`};
    }

    @media (min-width: ${limit}) {
      font-size: ${unitlessSizePx};

      font-size: ${isIE11 ? `calc(${unitlessSizePx} * 1)` : `calc(${unitlessSizePx} * var(--scale-font))`};
    }
  `;
};

export const SrOnly = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
